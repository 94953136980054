import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiSearch } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";

function Header() {

    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 992);
    let navigate = useNavigate();
    const [search, setSearch] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth >= 992);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup listener on unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const enterKey =(e) =>{
        if (e.key === 'Enter') {
            searchClicked()
          }
    }


    const searchClicked = () =>{
        if(search === ''){ 

            return
        }
        else if (search === ' '){
            console.log('sdf cs')
            setSearch('')
            return
            
        }else{

            localStorage.setItem('search' , search)
            // navigate('/search')
            const categoryUrl = `https://rentla.in/search`;
            window.location.href = categoryUrl;
        }
    }


    return (
        <>
            <header className="header-area clearfix header-hm9 transparent-bar">
                <div className='head_containers'>
                    <div className="header-top-area">
                        <div className="row mx-0 mx-lg-5 py-3">
                            <div className="col-lg-2 col-md-2 col-sm-2 col-12 col-xxl-2">
                                <div style={{ width: '115px' }} className="logo-hm9 text-center">
                                    <Link to='/'>
                                        <img className='hdddimggg ms-0 ms-lg-4' style={{ width: '115px' }} alt="logo" src="https://rentla.in/static/media/lo.a4dd52b9e88ff269370c.png" />
                                    </Link>
                                </div>
                            </div>

                            <div className="col-lg-8 col-md-8 col-sm-5 col-xxl-8">
                                {/* Menu items */}
                            </div>

                            {/* {isLargeScreen && (
                                <div className="col-lg-2 col-md-2 col-sm-2 col-12 col-xxl-2">
                                    <div className="header-right-wrap">
                                        <div className="same-style header-search">
                                            <div className="search-container">
                                                <div className='d-flex justify-content-start pt-2 '>
                                                    <input onKeyDown={(e) => enterKey(e)} onChange={(e) => {
                                                        if (e.target.value === ' ') {}
                                                        else {
                                                            setSearch(e.target.value);
                                                        }
                                                    }} value={search} className='head_search' type="text" placeholder="Laptops, Workstation, Pc, Etc..." name="search"  style={{
                                                        border: 'none',
                                                        outline: 'none', 
                                                        boxShadow: 'none', 
                                                      }}/>
                                                    <img  className='head_search_img' src="search_one.png"onClick={()=>searchClicked()}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )} */}
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;
