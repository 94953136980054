import React , { useState } from 'react'
import { Link } from 'react-router-dom'

import axios from 'axios'

export default function Footer(props) {

    const [ email , setEmail ] = useState('')
    const [ vali , setVali ] = useState(false)
    // const [ err , setErr ] = useState('Enter Valid Email')
    const [err, setErr] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const submit = async () => {
        setVali(false);
        setIsSuccess(false); // Reset success status
    
        // Check for empty email
        if (email === '') {
          setVali(true);
          setErr('Enter Email id');
          return;
        }
    
        // Check for valid email format
        if (!emailRegex.test(email)) {
          setVali(true);
          setErr('Enter a Valid Email Id');
          return;
        }
    
        // Make the API request
        try {
          const response = await axios.post('https://rentla.in/rentlacms/api/ve2/getEmail', {
            email: email,
          });
    
          if (response.data.status === true) {
            setEmail('');
            setVali(true);
            setIsSuccess(true); // Set success status to true
            setErr('Email Updated');
            setTimeout(() => setVali(false), 2000); // Hide the success message after 2 seconds
          } else {
            setVali(true);
            setErr('Enter Valid Email Id');
          }
        } catch (err) {
          setVali(true);
          setErr('Something Went Wrong');
          console.error(err);
        }
      };
    
  

  return (
    <footer style={{ paddingTop :  20 }}  >
        <div className="container web_cat_five">
            <div className="row">
                <div className='col-xl-6 col-xxl-6 col-md-4 col-sm-4 col-lg-4'>
                    <div className="copyright mb-30">
                        <div className="footer-logo">
                            <div style={{width : 200}}>
                            <Link to='/'>
                          <img className='' style={{ width: '115px' }} alt="logo" src="https://rentla.in/static/media/lo.a4dd52b9e88ff269370c.png" />
                      </Link>
                            </div>
                        </div>
                        {/* <p style={{fontSize : 12 ,  fontWeight : 500 , marginTop : 30}} >© 2022 <a >Rentla</a>.</p>
                        <p style={{fontSize : 12 ,  fontWeight : 500  }} > All Rights Reserved</p> */}
                        <p style={{fontSize : 12 ,  fontWeight : 500 , marginTop : 10}}>The Best Place for renting servers in Chennai,various affordable rental plans.</p>
                    </div>
                </div>
                <div className='col-xl-6 col-xxl-6 col-md-8 col-sm-12 col-lg-8'>
                    <div className='row'>
                    <div className='col-6 col-lg-3 mt-4'>
      <p className='footeresone' style={{ fontSize: 14, fontWeight: 500, color: '#000', marginBottom: 5,color:"#161942" }}>INFORMATION</p>
      <p className='footerestwo' style={{  color: 'rgba(69, 69, 69, 0.5)', fontSize: 12, fontWeight: 400, cursor: 'pointer', textDecoration: 'none' }}>
        <Link onClick={() => {
const rentNowUrl = `https://rentla.in/about_us`;
window.location.href = rentNowUrl;
}} style={{  color: 'rgba(69, 69, 69, 0.5)', textDecoration: 'none' }}>About us</Link>
      </p>
      <p className='footerestwo' style={{ color: 'rgba(69, 69, 69, 0.5)', fontSize: 12, fontWeight: 400, cursor: 'pointer', textDecoration: 'none' }}>
        <Link onClick={() => {
const rentNowUrl = `https://rentla.in/contact_us`;
window.location.href = rentNowUrl;
}} style={{ color: 'rgba(69, 69, 69, 0.5)', textDecoration: 'none' }}>Contact Us</Link>
      </p>
      <p className='footerestwo' style={{ color: 'rgba(69, 69, 69, 0.5)', fontSize: 12, fontWeight: 400, cursor: 'pointer', textDecoration: 'none' }}>
        <Link onClick={() => {
const rentNowUrl = `https://rentla.in/refund_return_policy`;
window.location.href = rentNowUrl;
}} style={{ color: 'rgba(69, 69, 69, 0.5)', textDecoration: 'none' }}>Return/Handover</Link>
      </p>
      <p className='footerestwo' style={{ color: 'rgba(69, 69, 69, 0.5)', fontSize: 12, fontWeight: 400, cursor: 'pointer', textDecoration: 'none' }}>
        <Link onClick={() => {
const rentNowUrl = `https://rentla.in/shipping_policy`;
window.location.href = rentNowUrl;
}} style={{ color: 'rgba(69, 69, 69, 0.5)', textDecoration: 'none' }}>Shipping Policy</Link>
      </p>
    </div>
                        <div className='col-6 col-lg-3 mt-4'>
                            <p className='footeresone' style={{fontSize :14 , fontWeight : 500 ,  color : '#161942' , marginBottom: 5}} >USEFUL LINKS</p>
                            <p className='footerestwo' style={{color : 'rgba(69, 69, 69, 0.5)' , fontSize : 12 ,  fontWeight : 400 , cursor : 'pointer'}}><Link style={{ color: 'rgba(69, 69, 69, 0.5)', textDecoration: 'none' }} onClick={() => {
const rentNowUrl = `https://rentla.in/terms`;
window.location.href = rentNowUrl;
}}>T & C</Link></p>
                            <p className='footerestwo' style={{color : 'rgba(69, 69, 69, 0.5)' , fontSize : 12 ,  fontWeight : 400 , cursor : 'pointer'}}><Link style={{ color: 'rgba(69, 69, 69, 0.5)', textDecoration: 'none' }}onClick={() => {
const rentNowUrl = `https://rentla.in/privacy_policy`;
window.location.href = rentNowUrl;
}}>Privacy Policy</Link></p>
                            <p className='footerestwo' style={{color : 'rgba(69, 69, 69, 0.5)' , fontSize : 12 ,  fontWeight : 400 , cursor : 'pointer'}}><Link style={{ color: 'rgba(69, 69, 69, 0.5)', textDecoration: 'none' }}onClick={() => {
const rentNowUrl = `https://rentla.in/damage_policy`;
window.location.href = rentNowUrl;
}}>Damage Policy</Link></p>
                          
                            <p className='footerestwo' style={{color : 'rgba(69, 69, 69, 0.5)' , fontSize : 12 ,  fontWeight : 400 , cursor : 'pointer'}}><Link style={{ color: 'rgba(69, 69, 69, 0.5)', textDecoration: 'none' }}onClick={() => {
const rentNowUrl = `https://rentla.in/faq`;
window.location.href = rentNowUrl;
}}>FAQ's</Link></p>  

<p className='footerestwo' style={{color : 'rgba(69, 69, 69, 0.5)' , fontSize : 12 ,  fontWeight : 400 , cursor : 'pointer'}}><Link style={{ color: 'rgba(69, 69, 69, 0.5)', textDecoration: 'none' }}onClick={() => {
const rentNowUrl = `https://rentla.in/blog`;
window.location.href = rentNowUrl;
}}>Blog</Link></p>          
                        </div>
                        <div className='col-12 col-lg-6 mt-4'>
                            <p className='footeresone' style={{fontSize :14 , fontWeight : 500 ,  color : '#161942' , marginBottom: 15}} >FOLLOW US</p>
                            <div className='d-flex justify-content-start'>
                                <a href='https://www.facebook.com/rentla.ind' target="_blank" >
                                    <img src="facebook.png" style={{width : 16 ,  height : 18 }} />
                                </a>
                                <a href='https://www.instagram.com/rentla.in/' target="_blank" >
                                    <img src="ins.png" style={{width : 16 ,  height : 18 , marginLeft : 10 }} />
                                </a>
                                <a href='https://www.linkedin.com/company/rentla-in/' target="_blank" >
                                    <img src="li.png" style={{width : 16 ,  height : 18 , marginLeft : 10 }} />
                                </a>
                                {/* <img src={twi} style={{width : 16 ,  height : 18 , marginLeft : 10 }} />
                                <img src={youtube} style={{width : 16 ,  height : 18 , marginLeft : 10}} /> */}
                            </div>
                            <div>
                            <div
      style={{
        width: '100%',
        padding: '3px 10px',
        borderRadius: 5,
        border: '1px solid #303651',
        marginTop: 20,
      }} className="mb-2"
    >
      <div className='row '>
        <div className='col-8 col-md-8'>
          <input
            placeholder='Enter Email Id'
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            // className="mb-2"
            style={{
              backgroundColor: 'unset',
              border: 'none',
              outline: 'none',
              boxShadow: 'none',
              width: '100%',
              padding: '10px',
              marginTop: '-1px',  
            }}
            type='text'
          />
        </div>
        <div className='col-4 col-md-4 d-flex align-items-center justify-content-md-end mt-md-0 mt-2'>
          <div
            onClick={submit}
            className='d-flex align-items-center justify-content-center mb-1 mb-lg-0  '
            style={{
              padding: '5px 15px',
              cursor: 'pointer',
              backgroundColor: '#303651',
              borderRadius: 5,
              border: 'none',
              outline: 'none',
              boxShadow: 'none',
            }}
          >
            <p className='lllllmp text-white' style={{ margin: 0 }}>Submit</p>
          </div>
         
        </div>
      </div>
    </div>            
    
     </div>  {vali && (
        <p style={{ color: isSuccess ? 'green' : 'red' }}>{err}</p>
      )}
                            {/* {
                                err === 'Email Updated' ? <p style={{color : 'green' , }}>{err}</p> : ''
                            } */}
                            
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    </footer>
  )
}