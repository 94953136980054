import './App.css';
import React from 'react';
import { BrowserRouter ,Route,Routes,} from 'react-router-dom';
import Home from './components/Home'
import Footer from './components/Footer'
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'bootstrap/js/src/carousel';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Header from './components/Header'
import Contact from './components/Contact'
import './index.css'; 

function App() {
  return (
    <BrowserRouter basename="/server">
      <Header  />
       <Routes>
        <Route path="/" element={<Home />}/>
       </Routes>
      <Footer/>
   </BrowserRouter>
  );
}
export default App;



