import React, { useState, useEffect } from "react";
import axios from "axios";
import CardComponent from "../CardComponent";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/animate.min.css'
import './css/slicknav.min.css'
import './css/magnific-popup.css'
import './css/nice-select.css'
import './css/responsive.css'
import './css/owl-carousel.css'
import { BsFillStarFill } from 'react-icons/bs';
import { BiStar } from 'react-icons/bi';
import { FaStar } from 'react-icons/fa';
import ImageSwapper from "./ImageSwapper";

export default function ProductList() {
  const [height, setHeight] = useState('180px'); 
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 640) { 
        setHeight('185px');
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const imageUrl = `https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/`;
  const[products,setProducts]=useState([]);
  const[catBanner,setCatBanner]=useState([]);
  const[banner,setBanner]=useState([]);
  const[review,setReview]=useState([]);

  const [activeIndex, setActiveIndex] = useState(0); 
  const handleCardClick = (index) => {
    setActiveIndex(index);
  };
  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        stars.push(<FaStar key={i} style={{ color: '#FCB806' }} />);
      } else {
        stars.push(<FaStar key={i} style={{ color: '#E0E0E0' }} />);
      }
    }
    return stars;
  };
  
  const[category,setCategory]=useState([])
  const [startIndex, setStartIndex] = useState(0); 
  const [selectedProductId, setSelectedProductId] = useState(null); 
  const [selectedCategoryId, setSelectedCategoryId] = useState(null); 
  const[catdetail,setCatdetail]=useState([]);


  const cardHeight = window.innerWidth < 781 ? '470px' : '290px';

  const cardStyle = {
    borderRadius: "29px",
    height: cardHeight,
    boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.3)', 

  };


  const handleRentNowProducts = (proId) => {
    const formData = new FormData();
    formData.append('proId', proId);

    fetch(`https://rentla.in/rentlacms/api/ve2/selectProduct`, {
      method: 'POST',
      headers: {
      },
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        console.log('Product selected:', data);
        setSelectedProductId(proId);
      })
      .catch(error => {
        console.error('Error selecting product:', error);
      });
    }

    const handleRentNowCategory = (CatId) => {
      console.log(`Category with ID ${CatId} clicked`);
  
      const formData = new FormData();
      formData.append('catId', CatId);
      formData.append('catId', CatId);

  
      fetch(`https://rentla.in/rentlacms/api/ve2/products`, {
        
        method: 'POST',
        headers: {
        },
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          console.log('Products for category:', data);

          setSelectedCategoryId(CatId); 
        })
        .catch(error => {
          console.error('Error selecting category:', error);
        });
    };
  
    
    const handleNext = () => {
      const nextIndex = startIndex + (isMobileView() ? 2 : 4);
      if (nextIndex < category.length) {
        setStartIndex(nextIndex);
      } else {
        setStartIndex(0); 
      }
    };
  
    const handlePrev = () => {
      let prevIndex = startIndex - (isMobileView() ? 2 : 4);
      if (prevIndex < 0) {
        prevIndex = Math.floor((category.length - 1) / (isMobileView() ? 2 : 4)) * (isMobileView() ? 2 : 4); 
      }
      setStartIndex(prevIndex);
    };
  
    const isMobileView = () => {
      return window.innerWidth < 768; 
    };
  

  const fetchData = async () => {
    try {
      const response = await axios.post('https://rentla.in/rentlacms/api/ve2/landingPage');
      setProducts(response.data.Data.product); 
      setCatBanner(response.data.Data.categoryBanner); 
      setBanner(response.data.Data.banner); 
      setReview(response.data.Data.reviews);
      setCategory(response.data.Data.category);

      console.log("productarray",products)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []); 
  

  const [activeCard, setActiveCard] = useState(1); 

  const handleHover = (index) => {
    setActiveCard(index);
  };

  const cardss = [
    { id: 1, imageSrc: "Group.png", altImageSrc: "25.png", title: "Dedicated Server", description: "Our dedicated servers are like having your private supercomputer. They're perfect if you run a website with tons of traffic, manage huge databases, or have apps that demand lots of power." },
    { id: 2, imageSrc: "dd.png", altImageSrc: "26.png", title: "Virtual Private Server", description: "VPS is a virtual machine that offers dedicated server resources on a physical server.VPS hosting provides you with a reserved amount of resources, giving you more control and customization than shared hosting." },
    { id: 3, imageSrc: "Outline.png", altImageSrc: "23.png", title: "Cloud Servers", description: "Change is the only constant, right? Our cloud servers are all about flexibility. You can easily adjust the resources as your needs evolve. This is a fantastic option for businesses with unpredictable workloads or plans for rapid growth" },
    { id: 4, imageSrc: "Layer 2.png", altImageSrc: "24.png", title: "Database Servers", description: "Data is like a library.Our database servers are the head librarian. They expertly store, organize, and manage your valuable information. These are a must-have if you're dealing with large amounts of data." }
  ];

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2, 
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1, 
      },
    },

    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 1, 
      },
    },
  ],
};


const settingsreview = {
  dots: true,
  initialSlide: 0, 
  infinite: true,
  speed: 500,
  slidesToShow: 2, // Initially show 2 slides per row
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1, // Show 1 slide per row on smaller screens
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 1, // Show 1 slide per row on smaller screens
      },
    },
  ],
};



  
  const images = [
    "https://s14.postimg.cc/z24osq8kt/pixel1.png",
    "https://s14.postimg.cc/l8gc3onp9/pixel2.png",
    "https://s14.postimg.cc/8ttk3djcd/pixel5.png",
    "https://s14.postimg.cc/8ttk3djcd/pixel5.png",
    "https://s14.postimg.cc/4kou178d9/pixel3.png",
    "https://s14.postimg.cc/4kou178d9/pixel3.png",
    "https://s14.postimg.cc/8ttk3djcd/pixel5.png",
    "https://s14.postimg.cc/z24osq8kt/pixel1.png"
  ];

  const handleThumbnailClick = (index) => {
    // Handle click on thumbnail, e.g., navigate to detail view or update state
    console.log(`Clicked thumbnail ${index + 1}`);
  };

  
  const cards = ['Card 1', 'Card 2', 'Card 3', 'Card 4']; // Example card titles

  const data = [
    { id: 1, imgSrc: 'image 13.png', title: 'Laptop Rental In Chennai', description: 'Description 1', buttonText: 'Button 1' },
    { id: 2, imgSrc: 'image 13.png', title: 'Desktop Rental In Chennai', description: 'Description 1', buttonText: 'Button 1' },
    { id: 3, imgSrc: 'image 13.png', title: 'Title 1', description: 'Description 1', buttonText: 'Button 1' },
    { id: 4, imgSrc: 'image 13.png', title: 'Title 1', description: 'Description 1', buttonText: 'Button 1' }
  ];
  

  const accordiandata = [
    {
      id: "1",
      question: "What is a server?",
      answer: "A server is a powerful computer or software system that provides data, resources, and services to other computers over a network.",
      target: "#collapseOne",
      targetId: "collapseOne",
      accClass: "accordion-collapse collapse show"

    },
    {
      id: "2",
      question: "Do I need a server for my business?",
      answer: "If your business requires centralized data storage, secure data access, or runs web applications, renting a server can be beneficial. Server rental provides flexibility and scalability for growing business needs",
      target: "#collapseTwo",
      targetId: "collapseTwo",
      accClass: "accordion-collapse collapse"

    },
    {
      id: "3",
      question: "Difference between a web server and a file server?",
      answer: "A web server hosts websites, serving web pages to users over the internet, while a file server stores and manages files, providing shared access to data within a network. Renting the right type of server depends on your specific business needs.",
      target: "#collapseThree",
      targetId: "collapseThree",
      accClass: "accordion-collapse collapse"

    },
    {

      id: "4",
      question: "How long will it take for my server to be ready for use?",
      answer: "The time it takes for your server to be ready for use can vary based on the provider and your specific requirements. Generally, it can range from a few hours to a couple of days. At Rentla, we prioritize quick and efficient service to ensure your server rental is ready for use as soon as possible.",
      target: "#collapseFour",
      targetId: "collapseFour",
      accClass: "accordion-collapse collapse"

    },
    {
      id: "5",
      question: "How do I choose the right server type for my business?",
      answer: "Consider your business needs, including traffic volume, resource requirements, and budget. Shared servers are cost-effective for small sites, VPS provides a balance of control and cost for growing businesses, and dedicated servers offer the highest performance for large-scale operations.",
      target: "#collapseFive",
      targetId: "collapseFive",
      accClass: "accordion-collapse collapse"

    },

  ]

  const [clickedCards, setClickedCards] = useState({
    first: false,
    middle: false,
    third: true, 
    fourth: false,
  });
  
  const handleClick = (id) => {
    setClickedCards(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };
  const isMobile = window.innerWidth <= 1200;

  return (
    <>
{/* <section className="">

<img src="bannerbg.png" className="img-fluid" style={{width: "100%"}} />

</section> */}



<section
      className={ ` ${isMobile ? "mobile-bg" : ""}`}
      style={{
        backgroundImage: `url('${isMobile ? "Mobile Banner G.png" : "bannerbg.png"}')`,
        backgroundSize: "cover",
        height: "800px",
        paddingTop: "80px",
      }}
    >
      <div className=" container section-container">
        <div className="row">
          <div className="col-12 col-xl-7 col-lg-6 col-md-6 order-2 order-lg-1">
            <div className="d-flex  text-center text-lg-start" >
              <div>
              <h1 className="custom-heading "style={{color:"#161942"}}>Everything you get what you need<br/> to Host your Website!</h1>
<p className="custom-paragraph fw-semibold mt-4 mb-0 mb-lg-5">Upto <span style={{ color: "#008000" }}>90% Discount</span> with <span style={{ color: "#008000" }}>Free Domain</span> Name Registration!</p>


                <div className="">

                  <div className="row mt-3 ">
                    <div className=" col-6 col-lg-5  mb-3 mb-lg-0">
                      <div className="d-flex align-items-center justify-content-lg-start">
                        <img src="Tick.png" alt="Icon1" />
                        <span className=" fw-semibold ticktext" style={{ color: "#303651" }}>100% security</span>
                      </div>
                    </div>
                    <div className="col-6 col-lg-5 mb-3 mb-lg-0">
                      <div className="d-flex align-items-center justify-content-lg-start">
                        <img src="Tick.png" alt="Icon1" />
                        <span className=" fw-semibold ticktext" style={{ color: "#303651" }}>Full virus protection</span>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3 ">
                  <div className=" col-6 col-lg-5 mb-3 mb-lg-0">
                      <div className="d-flex align-items-center justify-content-lg-start">
                        <img src="Tick.png" alt="Icon1" />
                        <span className=" fw-semibold ticktext" style={{ color: "#303651" }}>No extra charge</span>
                      </div>
                    </div>
                    <div className="col-6 col-lg-5 mb-3 mb-lg-0">
                      <div className="d-flex align-items-center justify-content-lg-start">
                        <img src="Tick.png" alt="Icon1" />
                        <span className=" fw-semibold ticktext" style={{ color: "#303651" }}>24 / 7 Customer Support</span>
                      </div>
                    </div>
                  </div>

                  {/* <div className="row mt-3 ">
                    <div className="col-6 col-lg-5 mb-3 mb-lg-0">
                      <div className="d-flex align-items-center justify-content-lg-start">
                        <img src="Tick.png" alt="Icon1" />
                        <span className=" fw-semibold ticktext" style={{ color: "#303651" }}>International Server</span>
                      </div>
                    </div>
                    <div className="col-6 col-lg-5 mb-3 mb-lg-0">
                      <div className="d-flex align-items-center justify-content-lg-start">
                        <img src="Tick.png" alt="Icon1" />
                        <span className="fw-semibold ticktext" style={{ color: "#303651" }}>Full virus Protection</span>
                      </div>
                    </div>
                  </div> */}

                </div>

                <div className="custom-button mt-0 mt-lg-5">
                  <button className="btn custom-button mb-3  py-3 " style={{ backgroundColor: "#FCB806", color: "white", borderRadius: "10px" }} onClick={() => {
                    const rentNowUrl = `https://rentla.in/products/Server/36/All/0`;
                    window.location.href = rentNowUrl;
                  }}>Get Started<img src="arrow-right.png" alt="Arrow" /></button>
                </div>

              </div>
            </div>
          </div>

          <div className="col-12 col-xl-5 col-lg-6 col-md-6 order-1 order-lg-2">
            <div className="text-center text-lg-center text-md-start">
              <ImageSwapper />
            </div>
          </div>
        </div>
      </div>

    </section>


<section className="schedule ">
      <div className="container mt-5" >
        <div className="schedule-inner mx-0 mx-lg-5">
          <div className="row">
            {cardss.map((card, index) => (
              <div className="col-lg-3 col-md-6 col-12 " key={card.id}>
                <div
                  className={` border mx-0  single-schedule ${activeCard === index ? 'active' : ''}`}
                  onMouseEnter={() => handleHover(index)}
                  onMouseLeave={() => handleHover(null)} 
                >
                  <div className="inner">
                    <div className="text-center">
                      <img src={activeCard === index ? card.altImageSrc : card.imageSrc} alt={`Image ${card.id}`} />
                    </div>
                    <h3 className="text-center pt-3"style={{color:"#F4B644",fontSize:"22px"}}>{card.title}</h3>
                    <p className={`text-center ${activeCard === index ? 'text-white' : 'text-black'}`} style={{fontSize:"14px"}}>{card.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>

   




<div class="container">
  <h2 className="text-center fw-semibold"> Server Rental In Chennai</h2>

  <div className="d-flex image-containerrr  "style={{justifyContent:"center"}}>
  <img src="image 36.png"class="img-fluid "></img>
  <img src="image 35.png"class="img-fluid"></img>
  <img src="ss2.png"class="img-fluid "></img>
  <img src="Server 3 1.png"class="img-fluid  mt-0 mt-lg-5"></img>
  </div>


  <div style={{textAlign:"center",fontSize:"18px",lineHeight:"1.7"}}className="pt-5 ">We're committed to offering server rentals in Chennai with incredible value. Our servers are the latest models, carefully checked, and ready to go. We have a wide range of options, including Rack Mount, Tower, and Blade servers from trusted brands like Fujitsu, HP, Dell, IBM, and Cisco.We take care of maintenance at no extra cost, so you can focus on your business.We know every business is different, that's why we offer flexible server rental plans that cater to your specific needs and budget. When you're ready, we deliver your server quickly, anywhere in Chennai. </div>
<div style={{textAlign:"center",fontSize:"18px",lineHeight:"1.7"}}>Experience the Rentla difference today!</div>

  <div class="text-center mt-4">
            <button className="btn btn-secondary py-3 px-3  mt-2" style={{ backgroundColor: "#FCB806", border: "none", borderRadius: "10px",fontSize:"14px" }}  onClick={() => {
              const rentNowUrl = `https://rentla.in/products/Server/36/All/0`;
              window.location.href = rentNowUrl;
            }}>Rent Now <img src="arrow-right.png" className="ps-2"alt="Arrow" /></button>          
              </div>

</div>

      
      <div class="container mt-5">
        <h2 class="text-center fw-bold pt-2 pb-4">Related Products</h2>
      </div>

      <div class="container">
        <div class="row">
{products.map((product) => (
  <div key={product.ProId} className="col-12 col-sm-6 col-md-6 col-lg-4 my-3">
    <div className="card bordered  h-100 d-flex flex-column"style={{borderColor:"#000000"}}>
      <div className="card-body d-flex flex-column">
        <div className="text-center">
          <img src={`${imageUrl}${product.ProImgPrimary}`} className="img-fluid" alt="No Image" />
        </div>
        <div className="text-center fw-semibold">{product.ProName}</div>
        <div className="mt-auto text-center"> 
          <button 
            className="btn btn-secondary py-2 mt-2" 
            style={{ backgroundColor: "#FCB806", border: "none", borderRadius: "10px" }} 
            onClick={() => {
              const rentNowUrl = `https://rentla.in/detail/${encodeURIComponent(product.ProName).replace(/%20/g, '-')}/${product.ProId}`;
              window.location.href = rentNowUrl;
            }}
          >
            Rent Now
          </button>
        </div>
      </div>
    </div>
  </div>
))}

        </div>


        <div class="text-center mt-2 mb-4">
        <button className="btn btn-secondary py-2 mt-2" style={{ backgroundColor: "#FCB806", border: "none", borderRadius: "10px" }}  onClick={() => {
              const rentNowUrl = `https://rentla.in/products/Server/36/All/0  `;
              window.location.href = rentNowUrl;
            }}>View All Servers</button>          </div>
      </div>


     <section className="availability pb-5" style={{ backgroundColor: '#D9D9D92B' }}>
      <div className="container">
      <style>
{`
  .my-custom-sliderr .slick-dots li button:before {
    font-family: 'slick';
    font-size: 16px !important; /* Increased specificity */
    line-height: 20px;
  }
`}
</style>
        <h2 className=" text-center fw-bold pt-5 pb-5"><img src="ppp.png"  className=" text-center img-fluid"style={{width:"350px"}}></img></h2>

        <div>
          <Slider className="my-custom-sliderr" {...settings}>
            {catBanner.map((item, index) => (
              <div className="col-12 col-md-6 col-lg-6 px-4" key={item.id}>
                <CardComponent
                  key={item.id}
                  CategoryBannerImage={item.CategoryBannerImage}
                  CategoryBannerTitle={item.CategoryBannerTitle}
                  CatId={item.CatId}
                  CatName={item.CatName}
                  title={item.title}
                  description={item.description}
                  buttonText={item.buttonText}
                  active={index === activeIndex}
                  onClick={() => handleCardClick(index)}
                  className=" "
                />
              </div>
            ))}
          </Slider>
        </div>

        <div className="position-relative w-100 overflow-hidden">
  <ul className="d-flex align-items-center justify-content-center position-absolute bottom-0 start-50 translate-middle-y w-100">
    {catBanner.map((_, index) => (
      <li
        key={index}
        className={`rounded-full ${index === activeIndex ? 'bg-secondary' : 'bg-light'} ${index === activeIndex ? 'active' : ''}`}
        style={{ width: "16px", height: "16px", cursor: "pointer" }} // Adjust the width and height here
        onClick={() => setActiveIndex(index)}
      ></li>
    ))}
  </ul>
</div>

      </div>
    </section>



    <h4 className="fw-bold fs-1 text-center py-2" style={{fontSize:"25px"}}><img src="ffaq.jpeg" style={{width:"350px"}}></img> </h4>

      <section className="faq"style={{backgroundColor:"#E7EFFC"}}>
        <div class=" ">
          <div class="row ">
            <div class="col-12 col-md-6 ">
              <div className="text-center">
              <img src="faq.jpeg" class="img-fluid "></img>
              </div>
            </div>
            <div class="col-12 col-md-6">

            {/* <div className="accordion" id="accordionExample">
            <div className="accordion" id="accordionExample">


            <div class="accordion-item">
              <h2 class="accordion-header">
                <button className="accordion-button fs-5 text-white fw-medium "
                          type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  style={{
                    backgroundColor: "#FCB806",
                    "--bs-accordion-arrow-color": "white",
                    outline: "none",
                    boxShadow: "none",
                    borderColor: "transparent",
                  }}
                >
What is Server                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p class="" style={{ fontSize: "15px" }}>
                  A server is a computer or system that provides resources, data, services, or programs to other computers, known as clients, over a network. In theory, whenever computers share resources with client machines they are considered servers resources with client machines they are considered servers share resources with client machines they are considered servers resources with client machines they are considered servers  machines they are considered servers machines they are considered servers.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                          className="accordion-button fs-5 text-white fw-medium "
                          type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                  style={{
                    backgroundColor: "#FCB806",
                    "--bs-accordion-arrow-color": "white",
                    outline: "none",
                    boxShadow: "none",
                    borderColor: "transparent",
                  }}
                >
What are the types Of Server?                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p class=""style={{ fontSize: "15px" }}>
                  A server is a computer or system that provides resources, data, services, or programs to other computers, known as clients, over a network. In theory, whenever computers share resources with client machines they are considered servers resources with client machines they are considered servers share resources with client machines.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                          className="accordion-button fs-5 text-white fw-medium "

                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                  style={{
                    backgroundColor: "#FCB806",
                    "--bs-accordion-arrow-color": "white",
                    outline: "none",
                    boxShadow: "none",
                    borderColor: "transparent",
                  }}
                >
What Is Dedicated Server?                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p style={{ fontSize: "15px" }}>
                  A server is a computer or system that provides resources, data, services, or programs to other computers, known as clients, over a network. In theory, whenever computers share resources with client machines they are considered servers.
                  </p>
                </div>
              </div>
            </div>




            <div class="accordion-item">
              <h2 class="accordion-header">
                <button className="accordion-button fs-5 text-white fw-medium "
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                  style={{
                    backgroundColor: "#FCB806",
                    "--bs-accordion-arrow-color": "white",
                    outline: "none",
                    boxShadow: "none",
                    borderColor: "transparent",
                  }}
                >
Difference Between Web Server And File Server?               </button>
              </h2>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p style={{ fontSize: "15px" }}>
                  A server is a computer or system that provides resources, data, services, or programs to other computers, known as clients, over a network. In theory, whenever computers share resources with client machines they are considered servers.
                  </p>
                </div>
              </div>
            </div>




            <div class="accordion-item">
              <h2 class="accordion-header">
                <button className="accordion-button fs-5 text-white fw-medium "
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                  style={{
                    backgroundColor: "#FCB806",
                    "--bs-accordion-arrow-color": "white",
                    outline: "none",
                    boxShadow: "none",
                    borderColor: "transparent",
                  }}
                >
            What is the difference between shared, VPS, and dedicated servers?
            </button>
              </h2>
              <div
                id="collapseFive"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p style={{ fontSize: "15px" }}>
                  A server is a computer or system that provides resources, data, services, or programs to other computers, known as clients, over a network. In theory, whenever computers share resources with client machines they are considered servers.
                  </p>
                </div>
              </div>
            </div>

  
</div>

</div>  */}




<div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button fs-5 text-white fw-medium"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne" style={{
                    backgroundColor: "#FCB806",
                    "--bs-accordion-arrow-color": "white",
                    outline: "none",
                    boxShadow: "none",
                    borderColor: "transparent",
                  }}
                >
What is a server?             </button>
              </h2>       
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body"style={{backgroundColor:"#D9D9D95E" }}>
                  <p style={{ fontSize: "15px"}}>
                  A server is a powerful computer or software system that provides data, resources, and services to other computers over a network.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed fs-5 text-white fw-medium"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                  style={{
                    backgroundColor: "#FCB806",
                    "--bs-accordion-arrow-color": "white",
                    outline: "none",
                    boxShadow: "none",
                    borderColor: "transparent",
                  }}
                >
                  Do I need a server for my business?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body"style={{backgroundColor:"#D9D9D95E" }}>
                  <p style={{ fontSize: "15px" }}>
                  If your business requires centralized data storage, secure data access, or runs web applications, renting a server can be beneficial. Server rental provides flexibility and scalability for growing business needs.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed fs-5 text-white fw-medium"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                  style={{
                    backgroundColor: "#FCB806",
                    "--bs-accordion-arrow-color": "white",
                    outline: "none",
                    boxShadow: "none",
                    borderColor: "transparent",
                  }}
                >
Difference between a web server and a file server?                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body"style={{backgroundColor:"#D9D9D95E" }}>
                  <p style={{ fontSize: "15px" }}>
                  A web server hosts websites, serving web pages to users over the internet, while a file server stores and manages files, providing shared access to data within a network. Renting the right type of server depends on your specific business needs.
                  </p>
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed fs-5 text-white fw-medium"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                  style={{
                    backgroundColor: "#FCB806",
                    "--bs-accordion-arrow-color": "white",
                    outline: "none",
                    boxShadow: "none",
                    borderColor: "transparent",
                  }}
                >
How long will it take for my server to be ready for use?               </button>
              </h2>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body"style={{backgroundColor:"#D9D9D95E" }}>
                  <p style={{ fontSize: "15px" }}>
                  The time it takes for your server to be ready for use can vary based on the provider and your specific requirements. Generally, it can range from a few hours to a couple of days. At Rentla, we prioritize quick and efficient service to ensure your server rental is ready for use as soon as possible.
                  </p>
                </div>
              </div>
            </div>




            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed fs-5 text-white fw-medium"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                  style={{
                    backgroundColor: "#FCB806",
                    "--bs-accordion-arrow-color": "white",
                    outline: "none",
                    boxShadow: "none",
                    borderColor: "transparent",
                  }}
                >
           How do I choose the right server type for my business?
            </button>
              </h2>
              <div
                id="collapseFive"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body"style={{backgroundColor:"#D9D9D95E" }}>
                  <p style={{ fontSize: "15px" }}>
                  Consider your business needs, including traffic volume, resource requirements, and budget. Shared servers are cost-effective for small sites, VPS provides a balance of control and cost for growing businesses, and dedicated servers offer the highest performance for large-scale operations.

                  </p>
                </div>
              </div>
            </div>






          </div>

            </div>
          </div>
        </div>


      </section>


     


      <section className="productslide">
  <div className="" style={{ position: "relative" }}>
    <img src="banner.jpg"  className="img-fluid large-screen-image" alt="Banner" />
    <div className="overlay-text">
      <h2 className="fw-bold  mt-0 pt-0 fs-4 px-5 mx-5">Namma Chennai Servers, Namma Chennai Speed. <span className="text-warning">Buy Yours Today</span>!</h2>
      <p className="container text-center text-muted fw-bold pt-2 ">Get the best server for your business needs and let us help with affordable server rentals in Chennai</p>
    </div>
  </div>
</section>


    <div class="pt-sm-3 pt-lg-0 " style={{ backgroundColor: '#EAF8FD' }}>
    <section className="productslidess">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8 col-md-10">
        <div className="card" style={{
   borderRadius: '27px',
   boxShadow: '0 10px 20px 0 rgba(0,0,0,0.1), 0 -10px 20px 0 rgba(0,0,0,0.1), 10px 0 20px 0 rgba(0,0,0,0.1), -10px 0 20px 0 rgba(0,0,0,0.1)', 
  }}>        <div className="card-body">
        <div className="row row-cols-2 row-cols-md-4 g-3 ms-0  mx-lg-5 ps-0 ">
          {category.slice(startIndex, startIndex + (isMobileView() ? 2 : 4)).map((image, index) => (
            <div key={index} className={`col mb-3 ${isMobileView() ? 'col-sm-6' : 'col-md-3'}`}>
              <img
                src={`${imageUrl}${image.CatImage}`}
                alt={`Thumbnail ${startIndex + index + 1}`}
                className="img-fluid mb-0 pb-0"
                onClick={() => {
                  const categoryUrl = `https://rentla.in/products/${encodeURIComponent(image.CatName).replace(
                    /%20/g,
                    '-'
                  )}/${image.CatId}/All/0`;
                  window.location.href = categoryUrl;
                }}
              />
              <p className="text-center fw-bold">{image.CatName}</p>
            </div>
          ))}
        </div>
        <button className="carousel-control-prev pt-5 " type="button" onClick={handlePrev}>
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="black" className="bi bi-chevron-left me-lg-5  me-0" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
                </svg>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next pt-5" type="button" onClick={handleNext}>
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="black" className="bi bi-arrow-right lg-me-5 sm-ms-0" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M8.354 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 8.354 3.354a.5.5 0 0 1 0-.708z"/>
                </svg>
                <span className="visually-hidden">Next</span>
              </button>
      </div>
    </div>
        </div>
      </div>
    </div>
  </section>




  {/* <section className=""> 
<div class="container">
  <div class="row">

    <div class="col-6">
      <h4>Best Server Rental Company in Chennai</h4>
      <div>If you're looking for reliable server rental services in Chennai, Rentla is your go-to provider. We offer leading brand servers for rent and are the most trusted company for businesses in the server rental industry.</div>
    </div>


    <div class="col-6">
      <h4>Any Type Of Server Configuration</h4>
      <div>At Rentla, we understand your business is unique. That's why we offer a wide range of servers, from simple setups for small businesses to powerful machines for data-heavy tasks. Find your perfect fit at Rentla.</div>
    </div>



    <div class="col-6">
      <h4>24/7 Customer Support</h4>
      <div>Our flexible rental plans make it easy to scale your infrastructure as your business grows, ensuring you always have the right resources at the right time. Our dedicated team of experts provides 24/7 customer support to ensure that any issues are resolved promptly. </div>
    </div>



    <div class="col-6">
      <h4>Immediate delivery</h4>
      <div>Need a server without the wait? we’ll deliver your rental server within the same day or within 2 working days. Our prompt service ensures that you can start using your server almost immediately.</div>
    </div>




   


  </div>
</div>

</section> */}

      <section className="availability pb-5 pt-5" style={{ backgroundColor: '#EAF8FD' }}>
    <div className="container">
    <style>
{`
  .my-custom-slider .slick-dots li button:before {
    font-family: 'slick';
    font-size: 16px !important; /* Increased specificity */
    line-height: 20px;
  }
`}
</style>

<Slider className="my-custom-slider" {...settingsreview} style={{ backgroundColor: '#EAF8FD' }}>
  {review.map((item) => (
    <div className="col-12 col-md-6 col-lg-6 px-2" key={item.LRID}>
      <div className="custom-card mb-3 mb-2 mt-2 px-5 py-3" style={cardStyle}>
        <div className="custom-card-content">
          <figure>
            <figcaption>
              <blockquote className="bsb-blockquote-icon mb-4">
                {item.LRReview}
              </blockquote>
            </figcaption>
          </figure>
        </div>
        <div className="custom-card-footer">
          <div className="reviewer-info d-flex align-items-center">
            <div>
              <img
                src={`${imageUrl}${item.LRImage}`}
                alt="Reviewer"
                className="reviewer-image"
              />
            </div>
            <div className="ms-3">
              <h4 className="mb-1 fw-semibold" style={{ fontSize: '14px', textTransform: 'uppercase' }}>{item.LRName}</h4>
              <h5 className="text-dark mb-0" style={{ fontSize: '13px', textTransform: 'uppercase' }}>{item.LRDesignation}</h5>
            </div>
          </div>
          <div className="stars">
            {renderStars(item.LRRating)}
          </div>
        </div>
      </div>
    </div>
  ))}
</Slider>

      <div className="position-relative w-100 overflow-hidden" style={{ backgroundColor: '#EAF8FD' }}>
        <ul className="d-flex align-items-center justify-content-center position-absolute bottom-0 start-50 translate-middle-y w-100">
          {data.map((_, index) => (
            <li key={index} className={`rounded-full bg-light ${index === 0 ? 'active' : ''}`}></li>
          ))}
        </ul>
      </div>
    </div>
  </section>



  </div>



      {/* <section class="schedule">
        <div class="container">
        <div class="schedule-inner d-flex justify-content-center align-items-center" style={{height: "100vh"}}>
      <div class="row">
     <div class="col-md-8">
      <div class="single-schedule first" style={{height: "310px"}}>
        <div class="inner">
          <div class="text-center">
            <img src="image 7.png" alt="Image description" />
          </div>

          <h3 class="text-center pt-3">Global Server</h3>
          <p class="text-center text-dark">If your company needs also is reliable dedicated server then it’s perfect for you.</p>
        </div>
      </div>
    </div>
  </div>
</div>

        </div>
      </section> */}




    </>
  );

}
